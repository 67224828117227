import React from "react"

const Footer = () => {
  return (
    <footer className="footer footer-light text-center">
      {/* <div className={`text-primary fs-md fw-bold`}>
        Want to know more about Instanto? Get in touch with us on{" "}
        <a className="fw-bold" href="mailto:connect@instanto.eu">
          connect@instanto.eu
        </a>
        .
      </div>

      <div className="fs-sm pt-2">
        &copy; Copyright 2020 <a href="/">Instanto</a>.
      </div> */}
    </footer>
  )
}

export default Footer
