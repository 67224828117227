import React, { useState } from "react"
import { Helmet } from "react-helmet"
import { Link } from "gatsby"
import Logo from "../../static/images/Instanto_logo.svg"

const Header = ({ nearTop }) => {
  const [navbarCollapsed, setNavbarCollapsed] = useState(true)

  const navToggle = () => {
    setNavbarCollapsed(!navbarCollapsed)
  }
  const closeNavbar = () => {
    setNavbarCollapsed(true)
  }

  return (
    <nav className="navbar navbar-multiline navbar-expand-lg">
      <Helmet
        htmlAttributes={{
          class: "mobile",
          lang: "en",
        }}
        bodyAttributes={{
          class: `loaded ${nearTop ? "top" : ""}`,
        }}
      >
        <title>Instanto</title>
        <meta
          name="description"
          content="Cross-border payments at internet speed"
        />
        <meta charSet="utf-8" />
        {/* <meta http-equiv="Cache-Control" content="max-age=200" />  */}
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={"favicon/apple-touch-icon.png"}
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={"favicon/apple-touch-icon.png"}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href={"favicon/favicon-32x32.png"}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href={"favicon/favicon-16x16.png"}
        />
        <link rel="manifest" href={"favicon/site.webmanifest"} />
        <link
          rel="mask-icon"
          href={"favicon/safari-pinned-tab.svg"}
          color="#5bbad5"
        />
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="theme-color" content="#ffffff" />
      </Helmet>
      <div className="navbar-line">
        <div
          className={`navbar-wrapper affix navbar-underlined navbar-light affix-skip affix-fixed bg-off-white ${
            !nearTop || !navbarCollapsed ? "shadow-dark-sm" : ""
          }`}
        >
          <div className="container px-0">
            <Link
              className="navbar-brand fw-regular pl-0 py-md-0"
              to="/"
              onClick={closeNavbar}
              style={{ display: "inline-flex" }}
            >
              <Logo className="mt-2 mt-md-1 mb-1" />
            </Link>

            <button
              className={`navbar-toggler ${navbarCollapsed ? "collapsed" : ""}`}
              type="button"
              aria-expanded={!navbarCollapsed}
              aria-label="Toggle navigation"
              onClick={navToggle}
            >
              <span className="navbar-toggler-icon"></span>
            </button>

            <div
              className={`collapse navbar-collapse ${
                !navbarCollapsed ? "show bg-off-white" : ""
              } `}
              id="navbar-collapse"
            >
              <ul className="navbar-nav navbar-nav-md ml-auto">
                <li className="nav-item">
                  <Link className="nav-link" to="/#top" onClick={closeNavbar}>
                    Home
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/#about" onClick={closeNavbar}>
                    About
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to="/#product"
                    onClick={closeNavbar}
                  >
                    Product
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to="/#pci-compliance"
                    onClick={closeNavbar}
                  >
                    PCI DSS compliance
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to="/#contact"
                    onClick={closeNavbar}
                  >
                    Contact
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </nav>
  )
}

export default Header
