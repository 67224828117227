import React from "react"
import Header from "./header"
import Footer from "./footer"

const Layout = ({ className, children }) => {
  const [nearTop, setNearTop] = React.useState(true)

  React.useEffect(() => {
    const handleScroll = e => {
      if (e.target.scrollingElement.scrollTop < 21) {
        setNearTop(true)
      } else if (e.target.scrollingElement.scrollTop > 20 && nearTop) {
        setNearTop(false)
      }
    }

    window.addEventListener("scroll", handleScroll)

    return function cleanupListener() {
      window.removeEventListener("scroll", handleScroll)
    }
  })

  return (
    <div className={className}>
      <Header nearTop={nearTop} />
      {children}
      <Footer />
    </div>
  )
}

export default Layout
